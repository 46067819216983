import * as React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout";
import Seo from "../components/seo";

const NotFoundPage = () => {
    return (
        <Layout isTop={false}>
            <Seo title="404: Not found"/>
            <h1>404: Not Found</h1>
            <p>お探しのページが見つかりません。</p>
            <Link to="/">トップへ</Link>
        </Layout>
    )
}

export default NotFoundPage
